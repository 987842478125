import {App} from './app'
import 'firebase/functions'

var Functions = App.functions()
// console.log(Functions)

if(location.hostname === 'localhost'){
	Functions.useFunctionsEmulator('http://localhost:5001');
	// Functions.useEmulator("localhost", 5001);
}

export { Functions }