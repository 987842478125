<template>
  <v-row justify="center">
    <v-col cols="12" md="10">
      <v-card shaped>
        <v-toolbar dark :color="getIngredientColor(item.type)" flat>
          <v-toolbar-title>Add Item</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="addInventory">
            <v-row class="align-center" justify="center" dense>

              <!-- Search for a Bottle -->
              <v-col cols="12" sm="10">
                <p class="text-overline mb-0 mt-2 text-decoration-underline">Search for a Bottle</p>
              </v-col>

              <v-col cols="12" sm="10">
                <v-autocomplete
                  clearable
                  no-filter
                  item-text="name"
                  v-model="selectedBottle"
                  :search-input.sync="search"
                  :loading="loadingBottles"
                  :items="foundBottles"
                  return-object
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar>
                      <img :src="item.image">
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{item.name}}</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-chip class="mx-1" v-for="itemSize in item.sizes" :key="itemSize.size">
                          {{itemSize.size}}
                        </v-chip>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                </v-col>


              <!-- Divider -->
              <v-col cols="12">
                <v-row dense class="align-center">
                  <v-col cols="5"><v-divider></v-divider></v-col>
                  <v-col cols="2" class="text-center">or</v-col>
                  <v-col cols="5"><v-divider></v-divider></v-col>
                </v-row>
              </v-col>

              <!-- Add Your Own Bottle -->
              <v-col cols="12" sm="10">
                <h1 class="text-overline mb-0 mt-2 text-decoration-underline">Add Your Own Bottle</h1>
              </v-col>

              <v-col cols="12" sm="10">
                <v-text-field
                  label="Distillery"
                  value="Test"
                  hint="Who distills the spirit?" persistent-hint
                  placeholder="Buffalo Trace, Suntory, St. George Spirits"
                  v-model="item.distiller"
                ></v-text-field>
            </v-col>

              <v-col cols="12" sm="10">
                <v-text-field
                  required
                  placeholder="Van Winkel, Tennessee Honey, Casamigos, Beafeater"
                  label="Brand/Maker/Name*"
                  hint="Which brand/maker (or name) is the spirit? Required." persistent-hint
                  v-model="item.maker"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="10">
                <ingredient-type-picker v-bind:type.sync="item.type"></ingredient-type-picker>
              </v-col>

              <!-- SubTypes for Gin, Whiskey, Rum, Tequila -->
              <v-col cols="12" sm="10">
                <ingredient-subtype-picker
                  :type="item.type"
                  v-bind:subtype.sync="item.subtype"
                ></ingredient-subtype-picker>
                <!-- Examples:
                  Tequila => Mezcal, Silver, Reposado, Anejo => Null
                  Whiskey => Canadian, Scotch, Irish, American
                -->
              </v-col>

              <!-- Variant  -->
              <v-col cols="12" sm="10" v-if="item.type == 'Whisky'">
                <ingredient-variant-picker
                  :subtype="item.subtype"
                  v-bind:variant.sync="item.variant"
                ></ingredient-variant-picker>
              </v-col>

              <!-- Subvariant -->
              <v-col cols="12" sm="10" v-if="item.type == 'Whisky'">
                <ingredient-subvariant-picker
                  :variant="item.variant"
                  v-bind:subvariant.sync="item.subvariant"
              ></ingredient-subvariant-picker>
              </v-col>

              <v-col cols="12" sm="10" class="text-center">
                <p class="text-overline mb-0 mt-2 text-decoration-underline">Ingredient Size</p>
                <v-row dense class="text-center justify-center">
                    <v-btn-toggle v-model="bottleSize" rounded :color="getIngredientColor(item.type)" class="py-2">
                      <v-btn value="375mL" :small="$vuetify.breakpoint.xs">
                        <span>375mL</span>
                        <v-icon small right v-if="bottleSize == '375mL'">
                          mdi-bottle-wine
                        </v-icon>
                      </v-btn>
                      <v-btn value="750mL" :small="$vuetify.breakpoint.xs">
                        <span>750mL</span>
                        <v-icon right v-if="bottleSize == '750mL'">
                          mdi-bottle-wine
                        </v-icon>
                      </v-btn>
                      <v-btn value="1.5L" :small="$vuetify.breakpoint.xs">
                        <span>1.5L</span>
                        <v-icon large right v-if="bottleSize == '1.5L'">
                          mdi-bottle-wine
                        </v-icon>
                      </v-btn>
                      <v-btn value="custom" :small="$vuetify.breakpoint.xs">
                        <span>Custom</span>
                        <v-icon v-if="bottleSize == 'custom'" :right="$vuetify.breakpoint.xs">
                          mdi-alert-decagram
                        </v-icon>
                      </v-btn>
                    </v-btn-toggle>

                    <template v-if="bottleSize == 'custom'">
                      <qty-measurement-picker
                        :qty.sync="item.orignalQty"
                        :measurement.sync="item.measurement"
                      >
                      </qty-measurement-picker>
                    </template>

                    <v-checkbox class="shrink ml-2"
                      v-model="itemUsed"
                      label="Is this item used?"
                    ></v-checkbox>

                    <v-col cols="12" sm="10" v-if="itemUsed">
                      <p class="text-overline mb-0 mt-2">Amount Used {{`(In ${item.measurement})`}}</p>
                      <v-text-field
                        @change="amtUsedChanged"
                        v-model="amtUsed"
                        label="Amount used"
                        placeholder="0"
                        type="number"
                        min="0"
                        step="1"
                        :rules="[...amtUsedRules, maxQtyUsed]"
                      ></v-text-field>
                    </v-col>
                </v-row>
              </v-col>

            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="add">
            Save
            <v-icon right>
              mdi-content-save
            </v-icon>
          </v-btn>
          <!-- <v-btn color="blue" text>
            Save & New
            <v-icon right>
              mdi-content-save-edit-outline
            </v-icon>
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
  import { Functions } from '../firebase/functions'

  export default {
    name: 'AddInventory',
    data: () => {
      return {
        bottleSize: '750mL',

        loadingBottles: false,
        foundBottles: [],
        selectedBottle: '',
        search: null,

        itemUsed: false,
        amtUsed: 0,
        amtUsedRules: [
          v => !!v || 'Please enter a value'
        ],

        item: {
          distiller: '',
          maker: '',
          type: '',
          subtype: '',
          variant: '',
          subvariant: '',
          measurement: 'mL',
          qty: 750,
          orignalQty: 750,
        },
        measurements: [
          "mL", "oz", "each", "twist", "dash", "cup"
        ],
      }
    },
    computed: {

    },
    watch: {
      search: function(value){
        // Don't randomly search small items
        if(value.length <= 1) return;

        // Don't redo search on selected item
        if(value == this.selectedBottle.name) return;

        // if still loading, forget about it
        if(this.loadingBottles) return;

        // Get recommendations
        this.recommendedBottles()
      },
      bottleSize: function (newValue, oldValue) {
        if(newValue == oldValue){
          // Do nothing
          return;
        } else {
          switch(newValue){
            case '375mL':
              this.item.qty = 375;
              this.item.orignalQty = 375;
              break;
            case '750mL':
              this.item.qty = 750;
              this.item.orignalQty = 750;
              break;
            case '1.5L':
              this.item.qty = 1500;
              this.item.orignalQty = 1500;
              break;
            default:
              this.item.qty = 0;
              break;
          }
        }
      },
      selectedBottle: function(value){
        var inferredType = '';

        if(/(wh\w*sk\w*y|b\w*rb\w*n|scotch)/i.test(value.name)){
          inferredType = 'Whiskey';
          this.item.maker = value.name;
          this.item.type = inferredType;
        }

        // Brandy 🍷
        else if(/br\w*nd\w*y/i.test(value.name)){
          inferredType = 'Brandy';
          this.item.maker = value.name;
          this.item.type = inferredType;
        }

        // Vodka 🥔
        else if(/(vodka)/i.test(value.name)){
          inferredType = 'Vodka';
          this.item.maker = value.name;
          this.item.type = inferredType;
        }

        // Rum 🍹
        else if(/\b(rh*ums?)\b/i.test(value.name)){
          inferredType = 'Rum';
          this.item.maker = value.name;
          this.item.type = inferredType;
        }

        // Gin 🍸
        else if(/\b(gin)\b/i.test(value.name)){
          inferredType = 'Gin';
          this.item.maker = value.name;
          this.item.type = inferredType;
        }

        // Tequila 🌵
        else if(/\b(te(c|q)\w*la)|sotol|rep(o|a)sado\b/i.test(value.name)){
          inferredType = 'Tequila';
          this.item.maker = value.name;
          this.item.type = inferredType;
        }

        // Liqueur 🥂'
        else if(/\b(liq|lic)\w*r\b/i.test(value.name)){
          inferredType = 'Liqueur';
          this.item.maker = value.name;
          this.item.type = inferredType;
        }

        // If I have NO CLUE what the heck the ingredient type is, then just set the name 🤷🏼‍♂️
        else{
          this.item.maker = value.name;
        }
      }
    },
    methods: {
      amtUsedChanged(value){
        this.item.qty = this.item.orignalQty - parseInt(value);
      },
      maxQtyUsed(value){
        if( value && value >= this.item.qty){
          return 'Amt should be less than the size.'
        } else {
          return true;
        }
      },
      recommendedBottles(){
        clearTimeout(this._timerId)

        // delay new call 500ms
        this._timerId = setTimeout(() => {
          this.loadingBottles = true;
          var getIngredients = Functions.httpsCallable('searchIngredients')
          getIngredients({searchTerm: this.search})
            .then( (result) => {
              this.foundBottles = result.data
              this.loadingBottles = false;
            })
        }, 1000)
      },
      add(){

        if(this.item.qty == 0 || this.item.orignalQty == 0){
          this.$emit('showAlert', {text: 'Please enter an amount. 🙏', type: 'error'});
          return;
        } else if(this.item.type == ''){
          this.$emit('showAlert', {text: 'Please select the type of item. 🙏', type: 'error'});
          return;
        } else if(this.item.maker == '') {
          this.$emit('showAlert', {text: 'Please set the name of item. 🙏', type: 'error'});
          return;
        }

        // Actually validate the code
        this.$emit('showAlert', {text: 'Success! The ingredient has been saved. Going back to inventory.', type: 'success'});
        var itemToAdd = {...this.item}
        if(typeof this.selectedBottle == "object"){
          itemToAdd["link"] = this.selectedBottle.link
          itemToAdd["image"] = this.selectedBottle.image
        }
        this.$store.dispatch('addIngredient', itemToAdd);

        setTimeout(() => {
          this.$router.push('/inventory')
        }, 2000);
      },
      getIngredientColor(ingredientType){
        let type = ingredientType.toLowerCase();

        switch(type){
          case 'whiskey':
            return 'yellow darken-3';
            break;
          case 'whisky':
            return 'yellow darken-3';
            break;
          case 'brandy':
            return 'red';
            break;
          case 'vodka':
            return 'brown';
            break;
          case 'rum':
            return 'deep-orange';
            break;
          case 'gin':
            return 'green';
            break;
          case 'tequila':
            return 'light-green';
            break;
          case 'liqueur':
            return 'teal';
            break;
          default:
            return 'blue-grey';
        }
      }
    }
  }
</script>